// # Header
// ## Logo
$configuration-logo-width: 330px;
$configuration-logo-width__md_down: 106px;
$configuration-logo__stuck-width: 242px;
$configuration-logo__portrait-width: 52px;
$configuration-logo__stuck__portrait-width: 52px;
// ### Logo aspect ratio variables. They have to be an integer
$configuration-logo-image-height: 80;
$configuration-logo-image-width: 330;
$configuration-logo-image__portrait-height: 150;
$configuration-logo-image__portrait-width: 109;
$configuration-logo__stuck-image__portrait-height: 130;// reduce the height to hide the bottom of a logo

// # Buttons
$configuration-button-font_style: $font-main;
$configuration-button_main-border_width: 2px;
$configuration-button_main__disabled-opacity: 1;
$configuration-button_main_border_radius: 0;
$configuration-button_alt_border_radius: 0;
$configuration-button-font_size: 16px;

// ## Global header Navigation
$configuration-category_navigation-list_1-padding: 40px 0 0;
$configuration-category_navigation-link-font: 600 18px/24px $font-alt;
$configuration-category_navigation-link-letter_spacing: 0.5px;
$configuration-category_navigation-link-text_tranform: uppercase;

// # Global label
$configuration-global_label-font: 600 12px/16px $font-main;
$configuration-global_label-letter_spacing: 0.5px;
$configuration-global_label-text_transform: capitalize;

// # Links
$configuration-link_2-underline_offset: 0;

// # Headings
$configuration-t_heading_1-font: 600 40px/56px $font-alt;
$configuration-t_heading_2-font: 400 32px/48px $font-alt;
$configuration-t_heading_3-font: 600 32px/40px $font-main;
$configuration-t_heading_6-font: 400 16px/24px $font-main;
$configuration-t_heading_4-font: 600 24px $font-alt;

// # Form
$configuration-form-input-font: 700 16px/22px $font-main;
$configuration-form-select-font: $configuration-form-input-font;

// # Product tile
$configuration-product_tile-cta_type: alt;
$configuration-product_tile-description-font_weight: normal;
$configuration-product_tile-description-letter_spacing: 0.2px;

// # Product badge
$configuration-product_badge-points-line_height: 0.8;
$configuration-product_badge-border_radius: 0;
$configuration-product_badge-font: 700 12px/24px $font-main;
$configuration-product_badge-font_size__small: 9px;

// # Product details
$configuration-product_details-price-font_size: 24px;

// # Promo
$configuration-promo-border_radius: 0;

// # Product quantity stepper
$configuration-product_quantity-border_width: 2px;

// # PLP & SEARCH
$configuration-load_progress-font: 16px;
$configuration-load_progress-transform: none;
$configuration-filter_selected-border_radius: 3px;

// # Age gate
$configuration-age_gate__mobile-background_image: url('../images/age-gate-background.jpg');
$configuration-age_gate-button_type: main;
$configuration-age_gate-button_modification: default;

// # Checkout
// ## Gift message
$color-checkout-gift_message_label: $color-accent;

// ## Confirmation page
$configuration-confirmation-account-background_image: url('../images/age-gate-background.jpg');
$configuration-confirmation-account-background_image__small: url('../images/age-gate-background.jpg');
$configuration-confirmation_create_account-button_modifications: default;

// # Error pages
$configuration-error_page__404-background_image: url('../images/age-gate-background.jpg');
$configuration-error_page__404__mobile-background_image: url('../images/age-gate-background.jpg');

// # Cart product details
$configuration-cart_product_details-name-font:500 16px/24px $font-main;
$configuration-cart_product_details-name-letter_spacing: 0.3px;
$configuration-cart_product_details-name-transform: capitalize;