@import 'account-flagship_tasting_room.scss';
@import 'account-wineclub.scss';

.your-shipment .shipment-card .shipment-type{
    background: $color-secondary-accent;
    font-family: $font-alt;
    &.confirm{
        background-color: $color-primary;
        color: $color-primary-background;
    }
}
.b-tab_list-tab.m-active{
    border-bottom: 3px solid $color-secondary-accent;
}

.b-track_notification{
    &-title{
        font: 400 16px/24px $font-main;
        letter-spacing: 0.3px;
    }
	&-inner{
        border: 2px solid $color-primary-accent;
        letter-spacing: 0.3px;
	}
    &-link{
        letter-spacing: 0.3px;
    }
}

.skip-shipment
{
    .content{
        .title{
            letter-spacing: 0.7px;
        }
        .undo{
            text-transform: capitalize;
            background-position: 0 40%;
        }
    }
}


// customize shipment page

.Customizeshipment-heading{
    font: 32px/46px $font-alt;
    letter-spacing: 0.6px;
}
.customizeShipment-title{
    font: 20px/32px $font-main;
    letter-spacing: 0.4px;
}

.shipment-details .saveShipment-cta{
    font: 400 16px/24px $configuration-button-font_style;
    letter-spacing: 0.4px;
}
.shipment-details .wineshipment-priceinfo{
    letter-spacing: 0.4px;
}

.wcproduct-name{
    font: 400 16px/24px $font-main;
    letter-spacing: .3px;
    padding: 3px;
}

.wcmembership-product-container{
    font: 16px/24px $font-main;
    letter-spacing: 0.3px;

    .wcmembership-manage{
        font-weight: normal;
    }
}

.b-account_card {
	&-spaceaddress {
		@include media(md-up) {
			width: 33%;
		}
	}

	&-defaultaddress {
		font: 16px/24px $font-main;
        font-weight: 400;
		letter-spacing: 0.3px;
	}

	&-button {
		font: 16px/24px $font-main;
		letter-spacing: 0.3px;
	}

	&-title {
		font: 400 24px/40px $font-main;
		letter-spacing: 0.4px;
	}
}

.b-account_card-link{
    font: 16px/24px $font-main;
    letter-spacing: 0.4px;
}

// customize shipment scss

.add-bottles-to-Shipment-container{
    .b-carousel-title{
        font: 20px/32px $font-main !important;
    }

    .b-carousel-slide {
		.b-product_tile-quantity {
			.b-product_quantity {
			margin-right: 0;
			}
		}
	}

    .b-product_tile-name{
        font: 16px/24px $font-main;
    }
    
    .b-product_tile-cta{
        font: 16px/24px $font-main;
        letter-spacing: 0.3px;
    }
    .b-product_tile-price{
        font: 16px/24px $font-main;
        letter-spacing: 0.3px;
    }
}

.swap-dialog-inner{
    .b-carousel-title{
        font: 20px/32px $font-main !important;
    }
}

.b-account_nav {
	&-item{
        font: 16px/24px $font-main;
        letter-spacing: 0.3px;
        text-transform: capitalize;
        &.m-active{
            font: 16px/24px $font-main;
        }
    }
}
