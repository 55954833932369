// # Applied colors palette #
//============================================
// ## Main colors ##
$color-primary: #4A4444;
$color-primary-background: #fff;
$color-select-option: #0c0c0c;
$color-error: #B42F2F;
$color-error-background: rgba(180,47,47,0.1);
$color-success: #508A33;
$color-promo-background: rgba(197,152,72,0.1);
$color-input-border: #737171;
$color-accent: #8D651C;
$color-label-text: #0c0c0c;
$color-header: #8D651C;
$color-secondary-accent: #8D651C;
$color-secondary: #8D651C;
$color-primary-accent: #C59848;
$color-track-notification: rgba(197, 152, 72, 0.1);
// ## Shades ##
$color-shade_1: #fafafa;
$color-shade_2: #efefef;
$color-shade_3: #777370;
$color-shade_4: #A9A5A5;
$color-shade_5: #4d4d4d;
$color-shade_6: #252525;
$color-shade_8: #C4C2C2;
$color-shade_9: #3F4142;
$color-shade_10: #508A33;
// ## GH/GF colors ##
$color-header_text: $color-header;
$color-header_alt: $color-header;
$color-footer_text: $color-primary;
$color-footer_alt: $color-primary;

// ## SVG Icon color
$color-svg-icon: $color-secondary-accent;

// ## Buttons ##
// ### Button Main ###
$color-button_main-background: $color-secondary-accent;
$color-button_main-border: $color-secondary-accent;
$color-button_main-text: $color-primary-background;
$color-button_main__hover-background: $color-primary-background;
$color-button_main__hover-border: $color-secondary-accent;
$color-button_main__hover-text:  $color-secondary-accent;
$color-button_main__disabled-background: $color-shade_4;
$color-button_main__disabled-text: $color-primary-background;
$color-button_main__disabled-border: $color-shade_4;
$color-button_main__inversed-background: $color-primary-background;
$color-button_main__inversed-border: $color-secondary-accent;
$color-button_main__inversed-text:$color-secondary-accent;
$color-button_main__inversed__hover-background: $color-secondary-accent;
$color-button_main__inversed__hover-border: $color-secondary-accent;
$color-button_main__inversed__hover-text: $color-primary-background;
$color-button_main__inversed__disabled-background: $color-primary-background;
$color-button_main__inversed__disabled-text: $color-shade_4;
$color-button_main__inversed__disabled-border: $color-shade_4;

// ### Alt ###
$color-button_alt-background: $color-primary-background;
$color-button_alt-border: $color-secondary-accent;
$color-button_alt-text: $color-secondary-accent;
$color-button_alt__hover-background: $color-secondary-accent;
$color-button_alt__hover-border: $color-secondary-accent;
$color-button_alt__hover-text: $color-primary-background;	
$color-button_alt__disabled-background: $color-primary-background;
$color-button_alt__disabled-text: $color-shade_4;
$color-button_alt__disabled-border: $color-shade_4;
$color-button_alt__inversed-background:  $color-secondary-accent;
$color-button_alt__inversed-border: $color-secondary-accent;
$color-button_alt__inversed-text: $color-primary-background;
$color-button_alt__inversed__hover-background: $color-primary-background;
$color-button_alt__inversed__hover-border: $color-secondary-accent;
$color-button_alt__inversed__hover-text: $color-secondary-accent;
// ## Badges ##
$color-badge-1: #4A4444;
$color-badge-2: #FBF7F1;
//============================================

// Semantic colors mapping to components
// # COMMON #
$color-text__dark: $color-primary;
$color-text__light: $color-shade_4;
$color-overlay-background: $color-shade_5;

// # COMPONENTS #
$color-product_image-background:$color-primary-background ;

// # PDP #
$color-product_gallery-thumb-border: #C59848;
$color-product_gallery-thumb__selected-border: rgba($color-primary, 0.35);

// ## PRODUCT BADGE ##
$color-badge_2-color: $color-badge-1;
$color-badge_1-color: $color-primary;
$color-badge_1-background: #FBF7F1;

// ## PRODUCT AVAILABILITY  ##
$color-product_availability-in_stock: $color-success;
$color-product_availability-low_stock: $color-success;
$color-product_availability-out_of_stock: $color-error;

// # PLP #
$color-filters-label: $color-input-border;
$color-sorting-label: $color-primary;

// ## PROGRESS BAR ##
$color-progress-bg: $color-primary-background;
$color-progress-value-bg: #C59848;

// ## MESSAGE ##
$color-message-text__inversed: $color-primary;

// ## Product tile ##
$color-product_tile-promo-background: $color-promo-background;
$color-product_tile-promo: $color-primary;
$color-quantity: #777370;

// ## Header ##
$color-header-background: $color-primary-background;
$color-header__transparent-text: $color-header_text;
$color-header__transparent__inverted-text: $color-header_alt;
$color-header__transparent-background-border: rgba($color-shade_4, 0.15);
$color-header__transparent-header_promo-background: $color-promo-background;
$color-navigation_flyout-background: $color-primary-background;
$color-hamburger_menu-background: $color-primary-background;
$color-hamburger_back_link-background: $color-primary-background;
$color-hamburger_account-background: $color-primary-background;
$color-cookies-background: $color-shade_5;//??
$color-header_promo__transparent_header-background: $color-badge-2;//+
$color-main_nav-divider: $color-shade_8;
$color-mobile_navigation-categories_divider: $color-secondary-accent;//+
$color-mobile_navigation-section_divider: $color-secondary-accent;
$color-shipmentdetails-border: #c6c6c6;

// ## Footer ##
$color-footer-background: $color-primary-background;
$color-footer-email_subscription-background: rgba($color-shade_6, 0.78);

// ## LINKS ##
$color-link: #8D651C;
$color-link__visited: #8D651C;
$color-link__disabled: $color-shade_4;

// ## NOTIFICATION ##
$color-notification-text: $color-primary;
$color-notification-background: $color-shade_4;

// # ERROR PAGES #
$color-error_410-text: $color-text__light;

// # PAGE DESIGNER #
$color-banner_2_region: $color-primary;

// # PDP #
$color-product_gallery-thumbs_control-background: rgba($color-shade_6, 0.9);


// ## BREADCRUMBS ##
$color-breadcrumb-current: $color-primary;
$color-breadcrumb-separator_bg: $color-secondary-accent ;
$color-breadcrumb-link_color: $color-secondary-accent ;

// ### SHIPPING STATES PANEL ###
$color-state__hover-background: #F9F8F8;

// # MY ACCOUNT #
$color-account_info_tile-bg: #F9F8F8;

// ## ACCOUNT NAVIGATION ##
$color-account_nav-item__active-text: $color-primary ;
$color-account_nav-item__active-bg: rgba(197,152,72,0.1);

// # AGE GATE #
$color-age_gate-text: $color-primary;
$color-age_gate-background: $color-primary-background;

// # ERROR PAGES #
$color-error_410-text: $color-text__dark;
$color-error_404-text: $color-text__dark;
$color-error_page-background: $color-primary-background ;
$color-error_page-text: $color-text__dark;

// ## CAROUSEL ##
$color-carousel_control:$color-secondary-accent;
$color-carousel_pagination_control: $color-primary-background;
$color-carousel_pagination_control__active: $color-secondary-accent;
$color-carousel_control__disabled:#A9A5A5;

// ## CHECKOUT ##
$color-checkout_confirmation-account-copy: $color-primary;

// # STORE LOCATOR #
$color-store_locator-border: #C4C2C2;
$color-store_locator-background: $color-primary-background;
$color-store_locator-close_background: #f9f8f8;
$color-store_locator-close_text: $color-primary;
$color-store_locator-nav_background: $color-primary-background;
$color-store_locator-nav_text: $color-primary ;
$color-store-border: #C4C2C2;
$color-store-hover: #C4C2C2;

// ## FORMS ##
$color-form-input: $color-primary;
$color-form-input-background: $color-primary-background;
$color-form-input-border: $color-input-border;
$color-form-label: $color-primary;
$color-form-caption: #737171;